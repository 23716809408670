import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/404/NotFound";
import React from "react";
import NewHomePage from "./pages/NewHomePage/NewHomePage";
import Sponsors from "./pages/Sponsors/Sponsors";
import NewNavbar from "./components/NewNavbar";

function App() {
    const [currentSection, setCurrentSection] = React.useState(0);
    return (
        <>
            <Routes>
            <Route path="/" element={<><NewNavbar currentSection={currentSection} /><NewHomePage setCurrentSection={setCurrentSection} currentSection={currentSection} /></>} />
                {/* <Route path="/" element={<><Navbar currentSection={currentSection} /><Home setCurrentSection={setCurrentSection} currentSection={currentSection} /></>} />
                <Route path="/home" element={<><Navbar currentSection={currentSection} /><Home setCurrentSection={setCurrentSection} currentSection={currentSection} /></>} />
                <Route path="/events" element={<><Navbar currentSection={null} /><Events /></>} />
                <Route path="/events/:category" element={<><Navbar currentSection={null} /><EventsPage /></>} />
                <Route path="/accomodation" element={<><Navbar currentSection={null} /><Accomodation /></>} />
                <Route path="/sponsors" element={<><Navbar currentSection={null} /><Sponsors /></>} />
                <Route path="/timeline" element={<><Navbar currentSection={null} /><Timeline /></>} />
                <Route path="/team" element={<><Navbar currentSection={null} /><OurTeam /></>} />
                <Route path="/contacts" element={<><Navbar currentSection={null} /><ContactUs /></>} />
                <Route path="/merch" element={<><Navbar currentSection={null} /><MerchPage /></>} />
                <Route path="/glitchpop" element={<><Navbar currentSection={null} /><Glitchpop /></>} />*/}
                <Route path="/sponsors" element={<><NewNavbar currentSection={null} /><Sponsors /></>} />
                <Route path="*" element={<NotFound />} /> 
            </Routes>
        </>
    );
}

export default App;
