import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import Social from "./Social";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars} from "@fortawesome/free-solid-svg-icons";
import {  faXmarkCircle, } from "@fortawesome/free-regular-svg-icons";
import icon from './icon.webp'
import { Button } from "react-bootstrap";
function Navbar({ currentSection }) {
    const [isActive, setIsActive] = useState(false);
    const [currentPage, setCurrentPage] = useState('')
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            setCurrentPage("Home")
        }
        else
            setCurrentPage(location.pathname.charAt(1).toUpperCase().replace('/', '') + location.pathname.slice(2))
    }, [location]);
    const handleClick = () => {
        const navmenu = document.querySelector(".navmenu");
        if (navmenu.classList.contains("show")) {
            setIsActive(false)
            navmenu.classList.remove("show");
        }
        else {
            setIsActive(true)
            navmenu.classList.add("show");
        }
    };

    return (
        <>
            <div className="d-none d-lg-flex justify-content-between gap-2 navbar" style={{
                position: "fixed",
                top: 0,
                height: 70,
                width: "100%",
                // borderRadius: "0 0 100% 100%",
                zIndex: 10,

            }}>
                
                <div className="d-none d-lg-flex justify-content-center align-items-center h-100 gap-1 gap-md-2 w-80">
                   
                    <Link to="/" className="navlink" >
                        <img alt="None" src={icon}  width={40} height={40} />
                    </Link>
                    

                </div>
                <div className="d-flex align-items-center justify-content-center" style={{ width: "10%","marginRight":"20px" }}>
                    <a href="/sponsors" target={'blank'} rel="noreferrer" className="fw-bold text-center m-auto p-1 px-2 rounded btnn btn-gradient" style={{
                        fontSize: 15,
                        opacity: 1,
                        transition: "all 0.25s ease-in-out",
                    }}
                    >
                        
                        PAST SPONSORS
                    </a>
                </div>

            </div>

            <div className="smallnavbar d-lg-none d-flex justify-content-between align-items-center" style={{
                position: "fixed",
                top: 0,
                height: 70,
                width: "100%",
                zIndex: 10,
                background: "linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
            }}>
                <Link to="/" style={{ color: currentPage === 'Home' ? "cyan" : "unset" }} className="navlink" >
                    <img src={icon} alt="" width={40} height={40} />
                </Link>
                <div className="px-3" style={{ display: !isActive ? 'block' : 'none' }}>
                    <Button variant="transparent text-cyan" onClick={handleClick} >
                        <FontAwesomeIcon icon={faBars} size='xl' />
                    </Button>
                </div>
            </div>

            <div className="navmenu">
                <div className="text-end p-3" >
                    <Button variant="transparent text-cyan" onClick={handleClick} >
                        <FontAwesomeIcon icon={faXmarkCircle} size='xl' />
                    </Button>
                </div>
                <div className="icon mb-5 text-center">
                    <img src={icon} alt="" width={60} height={60} />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                    <a href="/sponsors" target={'blank'} rel="noreferrer" className="fw-bold text-center m-auto p-1 px-2 rounded btnn btn-gradient" style={{
                        fontSize: 15
                    }}>
                        
                        PAST SPONSORS
                    </a>
                    

                </div>
            </div>

            <div className="navgradient">

            </div>
            <Social />
            


            
        </>

    );
}

export default Navbar;