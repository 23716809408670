import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import "./past_sponsors.css";

function Sponsors() {

    const sponsors = [

        {
            link: 'https://www.arcesium.com/',
            logo: 'arcesium.png'
        },
        {
            link: 'https://www.grabon.in/',
            logo: 'https://logo.clearbit.com/grabon.in'
        },
        {
            link: 'https://www.axisbank.com/',
            logo: 'https://logo.clearbit.com/axisbank.com'
        },
        {
            link: 'https://www.royalenfield.com/',
            logo: 'https://logo.clearbit.com/royalenfield.com'
        },
        {
            link: 'https://www.pabrais.com/',
            logo: 'https://logo.clearbit.com/pabrais.com'
        },
        {
            link: 'https://www.ftv.com/',
            logo: 'https://logo.clearbit.com/ftv.com'
        },
        {
            link: 'https://www.clovia.com/',
            logo: 'https://logo.clearbit.com/clovia.com'
        },
        {
            link: 'https://www.shareindia.com/',
            logo: 'https://logo.clearbit.com/shareindia.com'
        },
        {
            link: 'https://www.sbiyono.sbi/',
            logo: 'yono.png'
        },
        {
            link: 'https://www.rog.asus.com/',
            logo: 'rog.png'
        },
        {
            link: 'https://www.safexpress.in/',
            logo: 'safexpress.jpg'
        },
        {
            link: 'https://www.tattootattoa.com/',
            logo: 'tattoo.jpg'
        },
        {
            link: 'https://plumgoodness.com/',
            logo: 'https://logo.clearbit.com/plumgoodness.com'
        },
        {
            link: 'https://www.beardo.in/',
            logo: 'https://logo.clearbit.com/beardo.in'
        },
        {
            // link: 'https://www.safewtech.in/',
            logo: 'safew.jpg'
        },
        {
            // link: 'https://www.sparks.co.in/',
            logo: 'sparx.png'
        },
        {
            link: 'https://www.zebronics.com/',
            logo: 'https://logo.clearbit.com/zebronics.com'
        },
        {
            link: 'https://www.asus.com/',
            logo: 'https://logo.clearbit.com/asus.com'
        },
        {
            link: 'https://www.neufinesse.club/',
            logo: 'neu.png'
        },
        {
            link: 'https://www.fat-tiger.in/',
            logo: 'https://logo.clearbit.com/fat-tiger.in'
        },

    ]

    return (
        <div className="d-flex flex-column justify-content-center text-center sponsors-page" style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
        }}>
            <div style={{ height: 100 }}>

            </div>
            <div className="d-flex h-25 align-items-end justify-content-center pb-4">
                <div className="left-box">
                    <div style={{ color: "cyan" }} >Contact us for more info:</div>
                    <div> <b>Geetika Tirumalasetty</b> </div>
                    <a href="tel:7260955611" className='text-decoration-none '>
                        <FontAwesomeIcon icon={faPhone} />8454052361</a>
                    <a href="mailto:marketing.fluxus@iiti.ac.in" className="text-decoration-none ms-3">
                        <FontAwesomeIcon icon={faEnvelope} /> marketing.fluxus@iiti.ac.in </a>
                </div>
            </div>

            <br />
            <br />
            <div className="logos container">
                <div className="d-flex flex-wrap gap-4 justify-content-center align-items-center">
                    {
                        sponsors.map((sponsor, index) => {
                            return (
                                <div className="" style={{ width: 100, height: 100 }} key={index}>
                                    <a target={'_blank'} rel="noreferrer" className='w-100 h-100 d-flex rounded-4 align-items-center bg-light' href={sponsor.link}>
                                        <img className='w-100 rounded-4' src={sponsor.logo} alt={sponsor} />
                                    </a>
                                </div>
                            )
                        })
                    }

                </div>

                <div class="d-flex h-25 align-items-start justify-content-center pt-3 pt-md-5">
                    <div class="right-box">
                        Over the decade, Fluxus has hosted sponsors from across the globe, which has provided an unforgettable experience to both our visitors and the brand. The crowd engagement, media exposure, brand building and networking offered under Fluxus is unparalleled and one-of-a-kind. We are proud to have hosted some of the biggest names in the industry, and we are looking forward to hosting you as well. <br />
                    </div>
                </div>
            </div>
            <div style={{ height: 100 }}>

            </div>
        </div>
    )
}

export default Sponsors