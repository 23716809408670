import React from 'react'
import './Social.css'
import { YouTube, Instagram, LinkedIn, Facebook } from '@mui/icons-material'

function Social() {

   return (
      <div className="d-md-flex d-none  align-items-center social-wrapper">
         <div className="soc-shape">
            <div className="soc-shape2">
               <div
                  className="d-flex text-light align-items-center justify-content-center"
                  style={{ height: "90%", margin: "auto" }}
               >
                  <div className="social-icons">
                     <a href='https://www.linkedin.com/company/fluxus---iit-indore/mycompany/' target={'_blank'} rel="noreferrer" className='iconsx'>
                        <LinkedIn sx={{ fontSize: '20px' }} />
                     </a>
                     <a href='https://www.instagram.com/fluxus_iit_indore/' target={'_blank'} rel="noreferrer" className='iconsx'>
                        <Instagram sx={{ fontSize: '20px' }} />
                     </a>
                     <a href='https://www.facebook.com/fluxusiiti' target={'_blank'} rel="noreferrer" className='iconsx'>
                        <Facebook sx={{ fontSize: '20px' }} />
                     </a>
                     <a href='https://www.youtube.com/watch?v=ZFdLYrdBhBA&ab_channel=SalimSulaiman' target={'_blank'} rel="noreferrer" className='iconsx'>
                        <YouTube sx={{ fontSize: '20px' }} />
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Social