import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import './style.scss'

export default function NotFound() {

    useEffect(() => {
        var player = document.getElementById('player');

        // adjust skybox height to multiple of 32
        var h = document.getElementById('skybox').offsetHeight;
        var s_h = Math.round(h / 32) * 32;
        document.getElementById('skybox').style.height = `${s_h}px`;


        function walkLeft() {
            player.className = "walk-left";
            setTimeout(searchLeft, 1000);
        }
        function searchLeft() {
            player.className = "search-left";
            // player.style.left = "40%";
            setTimeout(walkRight, 3000);
        }
        function walkRight() {
            player.className = "walk-right";
            setTimeout(searchRight, 1000);
        }
        function searchRight() {
            player.className = "search-right";
            // player.style.left = "60%";
            setTimeout(walkLeft, 3000);
        }

        walkLeft();
    }, [])

    return (
        <div className='not-found'>
            <div id="skybox" className="skybox">
                <Link to={'/'} style={{ position: "absolute", left: 20, top: 20 }}>Go Home</Link>
                <div className="txt">
                    <h3>Game Over</h3>
                    <br /><p style={{ color: "#CD104D" }} >404 FILE NOT FOUND</p>
                    <br />
                </div>
                <div id="player" className="idle"></div>
                <div className="ground"></div>
            </div>
        </div>
    )
}
