import React from 'react'
import '../ContactUs/ContactUs.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

function NewHomePage() {
    return (
        <div className='contactwrapper'>
            <div>
                 <h4 className='flex justify-content-center' style={{"width":"100%","display":"flex","align-items":"center","margin-top":"60px"}}>COMING SOON</h4>
                <div style={{ "margin-top": "10px" }}>
                    <img alt="Main" id="contact_us_image" src="./main.png" className=' z-[10000] !w-[600px]' />
                </div>
                
            </div>
            <div className="contacts md-3">

                <div className="contact">
                    <p className='contactname'>Arunaditya Dash</p>
                    <p className='contactdes'>Overall Co-ordinator</p>
                    <a href="tel:8305083028" className='text-decoration-none '>
                        <FontAwesomeIcon icon={faPhone} />9344446245</a>
                    <a href="mailto:oc.fluxus@iiti.ac.in" className="text-decoration-none ">
                        <FontAwesomeIcon icon={faEnvelope} /> oc.fluxus<br className='d-sm-none' />@iiti.ac.in </a>
                </div>
                <div className="contact">
                    <p className='contactname'>Spandan Chakraborty</p>
                    <p className='contactdes'> Public Relations</p>
                    <a href="tel:9928664557" className='text-decoration-none '>
                        <FontAwesomeIcon icon={faPhone} />8582908301</a>
                    <a href="mailto:contactus.fluxus@iiti.ac.in" className="text-decoration-none ">
                        <FontAwesomeIcon icon={faEnvelope} /> contactus.fluxus<br className='d-sm-none' />@iiti.ac.in </a>
                </div>
                <div className="contact d-none d-md-flex">
                    <p className='contactname'>Saral Sethi</p>
                    <p className='contactdes'>Sales</p>
                    <a href="tel:9798843855" className='text-decoration-none '>
                        <FontAwesomeIcon icon={faPhone} />9111306423</a>
                    <a href="mailto:web.fluxus2022@gmail.com" className="text-decoration-none ">
                        <FontAwesomeIcon icon={faEnvelope} /> fluxus@iiti.ac.in </a>
                </div>
            </div>
        </div>
    )
}

export default NewHomePage

